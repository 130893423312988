import { useSelector } from 'react-redux';
import { Fade } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import GetInTouchForm from '../components/getInTouchForm';
import Image from '../components/image';

const Contact = () => {
  const contactData = useSelector(
    (state) => state.siteContents?.data?.contact_page
  );

  const inTouchImage = useSelector(
    (state) => state.siteContents?.data?.home_page?.in_touch_image
  );

  return (
    <>
      <Helmet>
        <title>{contactData?.page_title}</title>
        <meta name="description" content={contactData?.page_description} />
      </Helmet>
      <div className="section section-header">
        <div className="container">
          <Fade timeout={1000} in>
            <h2 className="section-title mb-5">{contactData?.main_title}</h2>
          </Fade>
          <div className="row">
            <Fade timeout={1000} in>
              <div className="col-lg-4">
                {contactData?.sections?.map((section) => (
                  <div key={section?.id}>
                    <h3 className="section-subtitle">{section?.office}</h3>
                    <p className="section-text w-75">
                      <a
                        href={`${section?.link_to_map}`}
                        target="_blank"
                        style={{ color: 'black', textDecoration: 'none' }}
                        rel="noreferrer"
                      >
                        {section?.address} <br />
                        {
                          !!section?.city && <span> {section?.city},  </span>
                        }
                        {
                          !!section?.state && <span> {section?.state} </span>
                        }
                        {
                          !!section?.zipcode && <span> {section?.zipcode} </span>
                        }
                      </a>
                      <br />
                      <a
                        style={{ textDecoration: 'none', color: '#ddad00' }}
                        href={`tel:${section?.phone}`}
                      >
                        {section?.phone}
                      </a>
                    </p>
                  </div>
                ))}
              </div>
            </Fade>

            <div className="col-8  d-lg-block">
              <div className="card-map">
                <Image src={contactData?.image} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col d-none d-lg-block">
                <div className="section-img-wrapper mg-r-35">
                  <div className="section-img">
                    <Image src={inTouchImage} alt="" />
                  </div>
                </div>
              </div>
              <GetInTouchForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
