import PropTypes from 'prop-types';
import Image from './image';

const TeamMemberModal = (props) => {
  const { member, show } = props;
  const handleClose = (e) => {
    if(e.target.id === 'modalTeam'){
    props.onClose();
    }
  };
  return (
    <div
      className={`modal fade modal-team ${show && 'show'} `}
      id="modalTeam"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      onClick={(e) => handleClose(e)}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              onClick={() => props.onClose()}
              data-bs-dismiss="modal"
              aria-label="Close"
            />

            <div className="media">
              <div className="media-img">
                <Image src={member?.image} alt="" />
              </div>
              <div className="media-body">
                <h3 className="media-title">{member?.name}</h3>
                <p className="media-text">{member?.position}</p>
              </div>
            </div>

            <p className="modal-text"
    dangerouslySetInnerHTML={{__html: member?.bio}}
    />
          </div>
        </div>
      </div>
    </div>
  );
};

TeamMemberModal.propTypes = {
  member: PropTypes.object,
  show: PropTypes.any,
  onClose: PropTypes.func
};

export default TeamMemberModal;
