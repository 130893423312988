import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import HouseGalleryCarousel from '../components/galleryCarousel';
import Image from '../components/image';

const House = () => {
  let { id } = useParams();
  const currPath = useLocation();

  const sections = useSelector(
    (state) => state.siteContents?.data?.location_page?.sections
  );

  const get_page = () => {
    let value = {};
    sections?.forEach((element) => {
      if (element.id == id) {
        value = element;
      }
    });

    return value;
  };

  const data = get_page();

  // const formatWebsiteName = (name) => {
  //   return formatUrl(name)?.replace('http://', '').replace('https://', '').replace('wwww.', '');
  // };

  const formatUrl = (url) => {
    if(url.endsWith('/')){
      return url.substring(0, url.length -1);
    }
    return url;
  };

  return (
    <div className="page-house">
      <div className="section section-header">
        <div className="container">
          <div className="banner-wrapper" >
            <div className="banner_text" style={{ padding:'10px'}} >
              <h2>{data?.name}</h2>
              <h6>
                {data?.address}
              {/*  {*/}
              {/*    data?.website &&*/}
              {/*    <>*/}
              {/*  <div className="circular-divider"></div>*/}
              {/*  <a  href={formatUrl(data?.website)} target="_blank" rel="noreferrer">{formatWebsiteName(data?.website)}</a>*/}
              {/*  </>*/}
              {/*}*/}
              </h6>
            </div>
            <div className="banner-text house">
              <p className={'house-content '}
               dangerouslySetInnerHTML={{
                 __html: data?.content
               }}
              ></p>
            </div>
            {
              data?.website && !data?.show_schedule_tour && !data?.show_join_wishlist?
                <>
                  <a href={formatUrl(data?.website)} className="btn btn-default" target="_blank" rel="noreferrer">
                    Visit WebSite
                  </a>
                </>
                : null
            }
            {
              !data?.website && data?.show_schedule_tour && !data?.show_join_wishlist ?
                <>
                  <Link to={`${currPath.pathname + '/' + id + '/tour'}`} className="btn btn-default">
                    Schedule a Tour
                  </Link>
                </>
                : null
            }
            {
              !data?.website && !data?.show_schedule_tour && data?.show_join_wishlist ?
                <>
                  <Link to={`${currPath.pathname + '/' + id + '/tour'}`} className="btn btn-default">
                    Join Waitlist
                  </Link>
                </>
                : null
            }
            <div className="section-img-wrapper three">
              <div className="section-img">
                <Image src={data?.banner_image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HouseGalleryCarousel images={data?.images} />

      {data?.floor_plans?.map((floor_plan, index) =>
        index % 2 === 0 ? (
          <div key={index} className="section section-floor pb-5">
            <div className="container">
              {index === 0 && (
                <h2 className="section-title mb-5">Floor Plans</h2>
              )}
              <div className="row flex-lg-column">
                <div className="col-lg-4 order-1">
                  <h3 className="section-subtitle mb-1">{floor_plan?.name}</h3>
                  <span className="text-muted">
                  {floor_plan.beds} bed{floor_plan.beds > 1 && 's'}  | {floor_plan.baths}  bath{floor_plan.baths > 1 && 's'} | {floor_plan.size} Sq/Ft
                  </span>
                </div>
                <div className="col-lg-4 order-3 order-lg-2 mt-3">
                  <p className="section-text"
                  dangerouslySetInnerHTML={{
                    __html: floor_plan?.descriptions
                  }}
                  ></p>
                  {currPath.pathname === '/locations/3/On-Cotton' ?(
                  <Link to={`${currPath.pathname + '/' + floor_plan.id + '/waitlist'}`} className="btn btn-default">
                    Join Waitlist
                  </Link>
                  ) : null
                  }
                </div>
                <div className="col-lg-8 order-2 order-lg-3 mt-3 mt-lg-0">
                  <div className="ps-lg-5">
                    <div className="section-img-wrapper">
                      <div className="section-img">
                        <Image src={floor_plan?.image} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div key={index} className="section section-floor pt-5">
            <div className="container">
              <div className="row flex-lg-column">
                <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                  <div className="pe-lg-5">
                    <div className="section-img-wrapper">
                      <div className="section-img">
                        <Image src={floor_plan?.image} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-1 order-lg-2">
                  <h3 className="section-subtitle mb-1">{floor_plan?.name}</h3>
                  <span className="text-muted">
                    {floor_plan.beds} bed{floor_plan.beds > 1 && 's'}  | {floor_plan.baths}  bath{floor_plan.baths > 1 && 's'} | {floor_plan.size} Sq/Ft
                    </span>
                </div>
                <div className="col-lg-4 order-3 mt-3">
                  <p className="section-text"
                  dangerouslySetInnerHTML={{
                    __html: floor_plan?.descriptions
                  }}
                  ></p>
                  {currPath.pathname === '/locations/3/On-Cotton' ?(
                  <Link to={`${currPath.pathname + '/' + floor_plan.id + '/waitlist'}`} className="btn btn-default">
                    Join Waitlist
                  </Link>
                  ) : null
                  }
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <div className="section secondary mb-5">
        <div className="container">
          <h2 className="section-title text-lg-center">Neighborhood</h2>
          <p className="section-text text-lg-center mx-auto mb-5"
            dangerouslySetInnerHTML ={{
              __html: data?.neighborhood_content
            }}
          >
          </p>

          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-subtitle mb-3">Schools</h2>
              <div className="section-img-wrapper three">
                <div className="section-img">
                  <Image src={data?.schools} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="section-subtitle mb-3">Shopping</h2>
              <div className="section-img-wrapper three">
                <div className="section-img">
                  <Image src={data?.shopping} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <h2 className="section-subtitle mb-3">Hospitals</h2>
              <div className="section-img-wrapper three">
                <div className="section-img">
                  <Image src={data?.hospitals} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <h2 className="section-subtitle mb-3">Public transport</h2>
              <div className="section-img-wrapper three">
                <div className="section-img">
                  <Image src={data?.public_transport} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="banner mt-5">

          {currPath.pathname === '/locations/3/On-Cotton' ?(
            <div className="banner-body">
              <div>
                <h6>{data?.reserve_text1}</h6>
                <p>{data?.reserve_phone}</p>
                <h6>{data?.reserve_text2}</h6>
              </div>
              <Link to={`${currPath.pathname +'/' + id + '/tour'}`} style={{justifyContent: 'center'}}className="btn btn-white mt-3 mt-lg-0">
                Schedule a Tour
              </Link>
            </div>
          ) : null
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default House;