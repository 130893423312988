import React, { useState } from 'react';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
// import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import { Link } from 'react-router-dom';
import TeamMemberModal from './teamModal';
import PropTypes from 'prop-types';
// import { useMediaQuery } from '@material-ui/core';
import Image from './image';


const TeamCarousel = (props) => {
  // const sliderRef = useRef(null);
  // //const sliderRef2 = useRef(null);
  //
  // const prevSlide = (e,sRef) => {
  //   e.preventDefault();
  //   sRef.current.slickPrev();
  // };
  // const nextSlide = (e, sRef) => {
  //   e.preventDefault();
  //   sRef.current.slickNext();
  // };
  const [memberToShow, setMemberToShow] = useState();
  const [showModal, setShowModal] = useState(false);

  const showTeamMemberModal = (member) => {
    setMemberToShow(member);
    setShowModal(true);
  };
  const hideMemberModal = () => {
    setMemberToShow();
    setShowModal(false);
  };

  // const breakpoint1 = useMediaQuery('(max-width:991px) and (min-width:768px)');
  // const breakpoint2 = useMediaQuery('(max-width:768px and (min-width:576px)');
  // const breakpoint3 = useMediaQuery('(max-width:576px)');
  // const breakpoint4 = useMediaQuery('(min-width:992px)');


  // const showNext = (valueLen) => {
  //   if(breakpoint1 && valueLen <= 3){
  //   return false;
  //   }
  //   else if(breakpoint1 && valueLen> 3 ){
  //     return true;
  //   }
  //
  //   if(breakpoint2 && valueLen <= 2){
  //
  //   return false;
  //   }else if(breakpoint2 && valueLen> 2 ){
  //     return true;
  //   }
  //   if(breakpoint3 && valueLen <= 1){
  //   return false;
  //   }else if(breakpoint3 && valueLen> 1 ){
  //     return true;
  //   }
  //   if(breakpoint4 && valueLen <= 4 ){
  //   return false;
  //   }
  //   return true;
  // };
  //
  //
  //
  // const sliderSettings = {
  //   customPaging: function customDots(i){
  //     return <div id={i} className="dot"></div>;
  //   },
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   swipeToSlide: true,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const [dragging, setDragging] = useState(false);
  const ShowTeamModal = (e, member) => {
    e.preventDefault();
    !dragging && showTeamMemberModal(member);
  };

  const mouseMoveDrag = (e) => {
    e.preventDefault();
    setDragging(true);  
  };

  const CompleteDrag = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const mouseDownCarousel = (e) => {
    e.preventDefault();
    document.onmousemove = mouseMoveDrag; 
    document.onmouseup = CompleteDrag;
    setDragging(false); 
  };


  // const board_members_settings = {
  //   ...sliderSettings,
  //   slidesToShow: props.board_members?.length >= 4 ? 4 : props.board_members?.length,
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: props.board_members?.length >= 3 ? 3 : props.board_members?.length,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: props.board_members?.length >= 2 ? 2 : props.board_members?.length,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: props.board_members?.length >= 1 ? 1 : props.board_members?.length,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  // const team_members_settings ={
  //   ...sliderSettings,
  //   infinite: showNext(props.team_members?.length) ? true : false,
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: props.team_members?.length >= 3 ? 3 : props.board_members?.length,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: props.team_members?.length >= 2 ? 2 : props.team_members?.length,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: props.team_members?.length >= 1 ? 1 : props.team_members?.length,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <div className="section section-overflow secondary section-lightslider team"
        style = {{

        }}
      >
        <div className="container">
          <h2 className="section-title">Operating Team</h2>
          <div
            className=" section section-team"
            style={{
              padding: '0 0 50px 0',
            }}
          >
            {[...props.team_members]?.sort((a, b) => a.order_position - b.order_position).map((member) => (
              <div key={member.id} className="card card-team">
                <div
                  onClick={(e) => ShowTeamModal(e, member)}
                  onMouseDown={(e) => mouseDownCarousel(e) }
                  className="card-img"
                >
                  <Image src={`${member?.image}`} alt="" />
                </div>
                <div className="card-body">
                  <h3 className="card-title">{member.name}</h3>
                  <p className="card-text">{member.position}</p>
                  <Link
                    to="#"
                    onClick={(e) => ShowTeamModal(e, member)}
                    onDrag={()=> null}
                    className="link"
                    data-bs-toggle="modal"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
          </div>
      </div>

      <div className="section section-overflow secondary section-lightslider board team">
        <div className="container">
          <h2 className="section-title">Board Members</h2>
          <div
            className="section section-team"
            style={{
              padding: '0 0 50px 0',
            }}
          >
            {[...props.board_members]?.sort((a, b) => a.order_position - b.order_position).map((member) => (
              <div key={member.id} className="card card-team"
                   style={{
                     width: '349px'
                   }}
              >
                <div
                  onClick={(e) => ShowTeamModal(e, member)}
                  onMouseDown={(e) => mouseDownCarousel(e) }
                  className="card-img"
                >
                  <Image src={`${member?.image}`} alt="" />
                </div>
                <div className="card-body">
                  <h3 className="card-title">{member.name}</h3>
                  <p className="card-text">{member.position}</p>
                  <Link
                    to="#"
                    onClick={(e) => ShowTeamModal(e, member)}
                    onDrag={()=> null}
                    className="link"
                    data-bs-toggle="modal"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <TeamMemberModal
        member={memberToShow}
        show={showModal}
        onClose={hideMemberModal}
      />
    </>
  );
};

TeamCarousel.propTypes = {
  team_members: PropTypes.array,
  board_members: PropTypes.array,
};

export default TeamCarousel;
