import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { postJoinWaitlist, postScheduleTour } from '../redux/misc/misc.actions';
import { useParams } from 'react-router-dom';


const UserEntryForm = (props) => {
  const dispatch = useDispatch();
  const currState = useSelector(state => state) || {};
  const { rel_id } = useParams();
  const {waitlist, tour, siteContents} = currState;
  const currentProperty  = siteContents.data.location_page.sections.find(sec => sec.id === parseInt(rel_id));
  const data = props?.page === 'waitlist_page' ? waitlist : tour;
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Email, setEmail] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [focusedOn, setFocusedOn] = useState({});
  const [errors, setErrors] = useState({});
  const findNumberCount = (str) => {
    let digitsArr = str.match(/\d+/g);
    if (digitsArr) {
      return digitsArr.join('').length;
    }
    return 0;
  };

  const submitContact = (e) => {
    e.preventDefault();

    const postData = {
      first_name: FirstName,
      last_name: LastName,
      email: Email,
      phone_number: PhoneNumber,
      page: props?.id
    };

    let hasError = false;
    let curr_errors = {};
    Object.keys(postData).forEach((key) => {
      setErrors(curr_errors);
      if (!postData[key]) {
        hasError = true;
        curr_errors = {
          ...curr_errors,
          [key]: 'This field is required',
        };
      }
      if (key === 'first_name' || key === 'last_name') {
        if (postData[key].length < 2) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field should have at least 2 characters',
          };
        }
      }
      if (key === 'phone_number') {
        const phone_reg = /^[\d ()+-]+$/g;
        const matched = postData[key].match(phone_reg);
        if (!matched) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field only accepts numbers and the symbols + - ( )',
          };
        }

        let number_of_numbers = findNumberCount(postData[key]);
        if (number_of_numbers < 7) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field accepts a minimum of 7 numbers',
          };
        }
      }
    });
    if(Object.keys(curr_errors).length !== 0){
      hasError = true;
    }

    setErrors(curr_errors);
    !hasError &&  dispatch(
      props?.page === 'waitlist_page' ?
      postJoinWaitlist({...postData, floorplan:rel_id }):
      postScheduleTour({...postData, locationssection:rel_id})

      );
  };
  console.log(props.page);
  useEffect(() => {
    data?.error && setErrors(data?.error);
  }, [setErrors, data?.error]);

  return (
    <div className="col-lg-4">
      <h2 className="section-title mb-5">{currentProperty?.show_schedule_tour  && props.page === 'schedule_tour_page' ? 'Schedule a Tour' : 'Join Waitlist' }</h2>
      <h3 className="section-subtitle mb-4"
    dangerouslySetInnerHTML={{
      __html: props?.subtitle
    }}
    />
      {data?.success && (
        <div className="alert alert-primary">"Submitted Successfully!"</div>
      )}

      {(data?.success === false  || Object.keys(errors).length !== 0 ) && (
        <div className="alert alert-warning">Kindly fix the errors below</div>
      )}

      <div className="mb-5">
        <div
          className={`form-group mb-3 required ${
            (FirstName || focusedOn?.FirstName) && 'focus'
          }`}
        >
          <label className="form-label">First name</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, FirstName: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, FirstName: false })}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.first_name  && 'red'
            }}
          />
        </div>

        <div
          className={`form-group mb-3 required ${
            (LastName || focusedOn?.LastName) && 'focus'
          }`}
        >
          <label className="form-label">Last name</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, LastName: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, LastName: false })}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.last_name  && 'red'
            }}
          />
        </div>
        <div
          className={`form-group mb-3 required ${
            (Email || focusedOn?.Email) && 'focus'
          }`}
        >
          <label className="form-label">Email address</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, Email: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, Email: false })}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.email  && 'red'
            }}
          />
        </div>
        <div
          className={`form-group mb-3 required ${
            (PhoneNumber || focusedOn?.PhoneNumber) && 'focus'
          }`}
        >
          <label className="form-label">Phone number</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, PhoneNumber: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, PhoneNumber: false })}
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.phone_number  && 'red'
            }}   
          />
        </div>
      </div>
      {!data?.success && (
        <button onClick={(e) => submitContact(e)} className="btn btn-default">
          {currentProperty?.show_schedule_tour  && props.page === 'schedule_tour_page' ? 'Schedule a Tour' : 'Join Waitlist' }
        </button>
      )}
    </div>
  );
};
UserEntryForm.propTypes = {
 title: PropTypes.string,
 subtitle: PropTypes.string,
 action_text: PropTypes.string,
 id: PropTypes.number,
 page: PropTypes.string
};

export default UserEntryForm;
