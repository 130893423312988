import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postUserContacts } from '../redux/misc/misc.actions';

const GetInTouchForm = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.userContact);
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Email, setEmail] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [focusedOn, setFocusedOn] = useState({});
  const [errors, setErrors] = useState({});
  const findNumberCount = (str) => {
    let digitsArr = str.match(/\d+/g);
    if (digitsArr) {
      return digitsArr.join('').length;
    }
    return 0;
  };

  const submitContact = (e) => {
    e.preventDefault();

    const data = {
      first_name: FirstName,
      last_name: LastName,
      email: Email,
      phone_number: PhoneNumber,
    };

    let hasError = false;
    let curr_errors = {};
    Object.keys(data).forEach((key) => {
      setErrors(curr_errors);
      if (!data[key]) {
        hasError = true;
        curr_errors = {
          ...curr_errors,
          [key]: 'This field is required',
        };
      }
      if (key == 'first_name' || key == 'last_name') {
        if (data[key].length < 2) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field should have at least 2 characters',
          };
        }
      }
      if (key == 'phone_number') {
        const phone_reg = /^[\d ()+-]+$/g;
        const matched = data[key].match(phone_reg);
        if (!matched) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field only accepts numbers and the symbols + - ( )',
          };
        }

        let number_of_numbers = findNumberCount(data[key]);
        if (number_of_numbers < 7) {
          curr_errors = {
            ...curr_errors,
            [key]: 'This field accepts a minimum of 7 numbers',
          };
        }
      }
    });
    if(Object.keys(curr_errors).length !== 0){
      hasError = true;
    }

    setErrors(curr_errors);
    !hasError && dispatch(postUserContacts(data));
  };

  useEffect(() => {
    data?.error && setErrors(data?.error);
  }, [setErrors, data?.error]);

  return (
    <div className="col-lg-4">
      <h2 className="section-title mb-5">Get In Touch</h2>
      <h3 className="section-subtitle mb-4">Let's Talk</h3>

      {data?.success && (
        <div className="alert alert-primary">"Submitted Successfully!"</div>
      )}

      {(data?.success === false  || Object.keys(errors).length !== 0 ) && (
        <div className="alert alert-warning">Kindly fix the errors below</div>
      )}

      <div className="mb-5">
        <div
          className={`form-group mb-3 required ${
            (FirstName || focusedOn?.FirstName) && 'focus'
          }`}
        >
          <label className="form-label">First name</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, FirstName: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, FirstName: false })}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.first_name  && 'red'
            }}
          />
        </div>

        <div
          className={`form-group mb-3 required ${
            (LastName || focusedOn?.LastName) && 'focus'
          }`}
        >
          <label className="form-label">Last name</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, LastName: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, LastName: false })}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.last_name  && 'red'
            }}
          />
        </div>
        <div
          className={`form-group mb-3 required ${
            (Email || focusedOn?.Email) && 'focus'
          }`}
        >
          <label className="form-label">Email address</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, Email: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, Email: false })}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.email  && 'red'
            }}
          />
        </div>
        <div
          className={`form-group mb-3 required ${
            (PhoneNumber || focusedOn?.PhoneNumber) && 'focus'
          }`}
        >
          <label className="form-label">Phone number</label>
          <input
            onFocus={() => setFocusedOn({ ...focusedOn, PhoneNumber: true })}
            onBlur={() => setFocusedOn({ ...focusedOn, PhoneNumber: false })}
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="text"
            className="form-control"
            required
            readOnly={data?.success}
            style = {{
              borderColor: errors?.phone_number && 'red'
            }}
          />
        </div>
      </div>
      {!data?.success && (
        <button onClick={(e) => submitContact(e)} className="btn btn-default">
          Submit
        </button>
      )}
    </div>
  );
};

export default GetInTouchForm;
