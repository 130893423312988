import { useSelector } from 'react-redux';
import LocationCarousel from '../components/locationsCarousel';
import { Link } from 'react-router-dom';
import GetInTouchForm from '../components/getInTouchForm';
import Fade from '@material-ui/core/Fade';
import { Helmet } from 'react-helmet';
import Image from '../components/image';

const Home = () => {
  const siteData = useSelector((state) => state.siteContents?.data);
  const { home_page, about_page, location_page, lifestyle_page } = siteData || {};

  return (
    <>
      <Helmet>
        <title>{home_page?.page_title}</title>
        <meta name="description" content={home_page?.page_description} />
      </Helmet>
      <div className="page-home">
        <div className="home-banner">
          <div className="home-banner-img">
            <Image src={home_page?.banner_image} alt="" />
          </div>
          <div className="container">
            <div
              className={`home-headline ${
                home_page?.text_has_background && 'textbg'
              }`}
            >
              <Fade timeout={1000} in={true}>
                <h1>{home_page?.main_title}</h1>
              </Fade>
            </div>
            <div
              className={`home-headline-text ${
                home_page?.text_has_background && 'textbg'
              }`}
            >
              <Fade timeout={1000} in={true}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: home_page?.main_content,
                  }}
                ></p>
              </Fade>

              <Link to="/lifestyle" className="btn btn-default">
                Read More
              </Link>
            </div>
          </div>
        </div>

        <div className="section secondary">
          <div className="container">
            <div className="row flex-column">
              <div className="col-lg-4 order-1">
                <Fade timeout={1000} in={true}>
                  <h2 className="section-title">{about_page?.main_title}</h2>
                </Fade>
              </div>
              <Fade timeout={1000} in={true}>
                <div className="col-lg-4 order-3 order-lg-2">
                  <p
                    className="section-text"
                    dangerouslySetInnerHTML={{
                      __html: about_page?.main_content,
                    }}
                  ></p>

                  <Link to="/about" className="btn btn-default">
                    Read More
                  </Link>
                </div>
              </Fade>
              <div className="col-lg-8 order-2 order-lg-3">
                <div className="section-img-wrapper">
                  <div className="section-img">
                    <Image src={about_page?.main_image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LocationCarousel
          locations={location_page?.sections}
          main_content={location_page?.main_content}
        />

        <div className="section secondary">
          <div className="container">
            <div className="row flex-column">
              <div className="col-lg-4 order-1">
                <h2 className="section-title">{lifestyle_page?.main_title}</h2>
              </div>
              <div className="col-lg-4 order-3 order-lg-2">
                <p
                  className="section-text"
                  dangerouslySetInnerHTML={{
                    __html: lifestyle_page?.main_content,
                  }}
                ></p>
                <Link to="/lifestyle" className="btn btn-default">
                  Read More
                </Link>
              </div>
              <div className="col-lg-8 order-2 order-lg-3">
                <div className="section-img-wrapper">
                  <div className="section-img">
                    <Image src={lifestyle_page?.main_image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col d-none d-lg-block">
                <div className="section-img-wrapper mg-r-35">
                  <div className="section-img">
                    <Image src={home_page?.in_touch_image} alt="" />
                  </div>
                </div>
              </div>
              <GetInTouchForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
