import { GETSITECONTENT } from './site.types';
import api from '../../utils/api';

export const getSiteContent = () => {
  return {
    type: GETSITECONTENT,
    payload: api({
      url: '/site_contents/',
      method: 'GET',
    }),
  };
};
