import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Fade} from '@material-ui/core';
import {useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Helmet} from 'react-helmet';
import {Link as ScrollLink} from 'react-scroll';
import Image from '../components/image';

const divStyle = {
  color: 'black',
  background: 'black',

};

const Location = () => {
    const [mapSelectedSection, setMapSelectedSection] = useState(null);
    const locationsData = useSelector(
        (state) => state.siteContents?.data?.location_page
    );
    const [locationShown, setLocationShown] = useState(false);
    let mobile = useMediaQuery('(max-width:991px)');
    const location = useLocation();
    const [showInfoFor, setShowInfoFor] = useState();

    useEffect(() => {
        document.onclick = (e) => {
            const imgViewId1 = document.getElementById('image-1');
            const mapPoints = document.getElementsByClassName('map-point');
            const locationAreas = document.getElementsByClassName('media-location');
            const isPointClicked = [...mapPoints].some(map_point => (map_point.contains(e.target) || map_point === e?.target));
            const isLocationClicked = [...locationAreas].some(locationArea => (locationArea.contains(e.target) || locationArea === e?.target));
            if (e.target.classList.contains('view-on-map')) {
                return;
            }
            if (e.target.localName === 'a') {
                setLocationShown(false);
                setMapSelectedSection(null);
                return;
            }
            if (!imgViewId1?.contains(e.target) && imgViewId1 !== e?.target) {
                if (locationShown && !isPointClicked && !isLocationClicked) {
                    setLocationShown(false);
                    setMapSelectedSection(null);
                } else {
                     setLocationShown(true);
                }
            }
        };
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const locationId = params.get('current_location');
        if (locationId) {
            setMapSelectedSection(locationsData?.sections?.find(sect => sect.id === parseInt(locationId)));
        }
    }, [location]);

    return (
        <>
            <Helmet>
                <title>{locationsData?.page_title}</title>
                <meta name="description" content={locationsData?.page_description}/>
            </Helmet>

            <div className="section section-header">
                <div className="container">
                    <Fade timeout={1000} in>
                        <h2 className="section-title mb-5">Find your new home</h2>
                    </Fade>
                    <div className="row flex-lg-row-reverse">
                        <div className="col-lg-7 card-maps"
                        >
                            <div className="card-maps"
                              style = {{
                                 position: !mobile && 'sticky',
                                 top: !mobile && '150px',
                              }}
                            >
                             <div className="card-map">
                                {!mobile && (
                                    <div
                                        className={`map-point t-0p ${mapSelectedSection && mapSelectedSection.map_image_index === 1 && 'show'}`}
                                        style={{
                                            height: '450px',
                                            display: 'none',
                                            width: '244px',
                                            background: '#F7F4EB',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.13)',
                                            borderRadius: 0,
                                            right: '0%',
                                            zIndex: 2,
                                        }}
                                    >
                                        <div>
                                            <Image
                                                style={{height: '246px'}}
                                                src={mapSelectedSection?.image}
                                            />
                                            <h1
                                                style={{
                                                    fontFamily: 'Alegreya Sans SC',
                                                    fontSize: '21px',
                                                    lineHeight: '124%',
                                                    color: '#1D242B',
                                                    padding: '16px 16px 0 16px',
                                                }}
                                            >{`${mapSelectedSection?.title}`}</h1>
                                        </div>
                                        <div>
                                            <p
                                                style={{
                                                    fontFamily: 'Georgia',
                                                    fontSize: '14px',
                                                    lineHeight: '128%',
                                                    color: '#98999B',
                                                    padding: '8px 16px',
                                                }}
                                            >
                                                {mapSelectedSection?.address}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'right',
                                                paddingRight: '18px',
                                                paddingBottom: '18px',
                                                position: 'absolute',
                                                bottom: 0,
                                            }}
                                        >
                                            <div>
                                                {
                                                    !mapSelectedSection?.is_coming_soon &&
                                                    <Link
                                                        to={`/locations/${mapSelectedSection?.id}/${mapSelectedSection?.name?.replaceAll(' ', '-')}`}
                                                        className="link"
                                                    >
                                                        Read More
                                                    </Link>
                                                }
                                                {(mapSelectedSection?.address && !(mapSelectedSection?.address === 'Details coming soon!')) &&
                                                <>
                                                    {
                                                        !mapSelectedSection?.is_coming_soon &&
                                                        <span className="link separator">|</span>
                                                    }
                                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${mapSelectedSection?.address}`}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                       className="link direction-link"
                                                    >
                                                        Directions
                                                    </a>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Image src={locationsData?.image} alt="" id="image-1"/>
                                {
                                    mobile ?
                                        <>
                                            {locationsData?.sections.filter(section => section.map_image_index === 1).map((section) => (
                                              section.map_point !== 'none' ?
                                                <ScrollLink
                                                  key={section.id}
                                                  to={`${section.id}`}
                                                >
                                                    <div
                                                        className={`map-point ${section.map_point}`}
                                                    >
                                                        <div
                                                          className="point-logo"
                                                          style={section.is_coming_soon ? divStyle : {color: 'transparent' }}
                                                        >
                                                            <Image src={locationsData?.logo} alt=""/>
                                                        </div>
                                                    </div>
                                                </ScrollLink>
                                              :
                                                  <>
                                                  </>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {locationsData?.sections.filter(section => section.map_image_index === 1).map((section) => (
                                                section.map_point !== 'none' ?
                                                    <div
                                                      key={section.id}
                                                      id={`${section.id}`}
                                                      className={`map-point ${section.map_point}`}
                                                    >
                                                        <div className="point-logo"
                                                             style={section.is_coming_soon ? divStyle : {color: 'transparent' }}
                                                             onMouseOver={() => setShowInfoFor(section.id)}
                                                             onMouseLeave={() => setShowInfoFor(null)}
                                                          // style={{ background: mapSelectedSection?.id === locationsData?.sections[2]?.id && '#b28d055c' }}

                                                             onClick={() =>
                                                               setMapSelectedSection(section)
                                                             }
                                                        >
                                                            <Image src={locationsData?.logo} alt=""/>
                                                        </div>
                                                        <div
                                                          className={`map-info ${showInfoFor === section.id ? '' : 'd-none'}`}>
                                                            <h3>{section.title.replace('Communities', '')}: </h3>
                                                            <span>{section.address}</span>
                                                        </div>
                                                    </div>
                                                :
                                                  <>
                                                  </>
                                            ))}
                                            {/*<div*/}
                                            {/*    className="map-point t-65p l-26p"*/}
                                            {/*>*/}
                                            {/*    <div className="point-logo" style={divStyle}*/}
                                            {/*         //onMouseOver={() => setShowInfoFor(null)}*/}
                                            {/*         onMouseOver={() => setShowInfoFor(locationsData?.sections[4].id)}*/}
                                            {/*         onMouseLeave={() => setShowInfoFor(null)}*/}
                                            {/*         // style={{ background: mapSelectedSection?.id === locationsData?.sections[4]?.id && '#b28d055c' }}*/}

                                            {/*        onClick={() =>*/}
                                            {/*          //setMapSelectedSection(null)*/}
                                            {/*          setMapSelectedSection(locationsData?.sections[4])*/}
                                            {/*        }*/}
                                            {/*    >*/}
                                            {/*        <Image src={locationsData?.logo} alt=""/>*/}
                                            {/*    </div>*/}
                                            {/*    <div*/}
                                            {/*        className={`map-info ${showInfoFor === locationsData?.sections[4].id ? '' : 'd-none'}`}>*/}
                                            {/*        <h3> {locationsData?.sections[4].title.replace('Communities', '')}: </h3>*/}
                                            {/*        <span>{locationsData?.sections[4]?.address}</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </>
                                }
                             </div>
                             <div className="card-map">
                                 {!mobile && (
                                    <div
                                        className={`map-point t-0p ${mapSelectedSection && mapSelectedSection.map_image_index === 2 && 'show'}`}
                                        style={{
                                            marginTop: '20px',
                                            height: '450px',
                                            display: 'none',
                                            width: '244px',
                                            background: '#F7F4EB',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.13)',
                                            borderRadius: 0,
                                            right: '0%',
                                            zIndex: 2,
                                        }}
                                    >
                                        <div>
                                            <Image
                                                style={{height: '246px'}}
                                                src={mapSelectedSection?.image}
                                            />
                                            <h1
                                                style={{
                                                    fontFamily: 'Alegreya Sans SC',
                                                    fontSize: '21px',
                                                    lineHeight: '124%',
                                                    color: '#1D242B',
                                                    padding: '16px 16px 0 16px',
                                                }}
                                            >{`${mapSelectedSection?.title}`}</h1>
                                        </div>
                                        <div>
                                            <p
                                                style={{
                                                    fontFamily: 'Georgia',
                                                    fontSize: '14px',
                                                    lineHeight: '128%',
                                                    color: '#98999B',
                                                    padding: '8px 16px',
                                                }}
                                            >
                                                {mapSelectedSection?.address}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'right',
                                                paddingRight: '18px',
                                                paddingBottom: '18px',
                                                position: 'absolute',
                                                bottom: 0,
                                            }}
                                        >
                                            <div>
                                                {
                                                    !mapSelectedSection?.is_coming_soon &&
                                                    <Link
                                                        to={`/locations/${mapSelectedSection?.id}/${mapSelectedSection?.name?.replaceAll(' ', '-')}`}
                                                        className="link"
                                                    >
                                                        Read More
                                                    </Link>
                                                }
                                                {(mapSelectedSection?.address && !(mapSelectedSection?.address === 'Details coming soon!')) &&
                                                <>
                                                    {
                                                        !mapSelectedSection?.is_coming_soon &&
                                                        <span className="link separator">|</span>
                                                    }
                                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${mapSelectedSection?.address}`}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                       className="link direction-link"
                                                    >
                                                        Directions
                                                    </a>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Image src={locationsData?.image_id2} alt=""  id="image-2"
                                       style={{
                                           paddingTop: '20px',
                                       }}
                                />
                               {
                                mobile ?
                                 <>
                                  {locationsData?.sections.filter(section => section.map_image_index === 2).map((section) => (
                                    section.map_point !== 'none' ?
                                      <ScrollLink
                                        key={section.id}
                                        to={`${section.id}`}
                                      >
                                          <div
                                              className={`map-point ${section.map_point}`}
                                          >
                                              <div
                                                className="point-logo"
                                                style={section.is_coming_soon ? divStyle : {color: 'transparent' }}
                                              >
                                                  <Image src={locationsData?.logo} alt=""/>
                                              </div>
                                          </div>
                                      </ScrollLink>
                                    :
                                        <>
                                        </>
                                  ))}
                              </>
                              :
                              <>
                                  {locationsData?.sections.filter(section => section.map_image_index === 2).map((section) => (
                                      section.map_point !== 'none' ?
                                          <div
                                            key={section.id}
                                            id={`${section.id}`}
                                            className={`map-point ${section.map_point}`}
                                          >
                                              <div className="point-logo"
                                                   style={section.is_coming_soon ? divStyle : {color: 'transparent' }}
                                                   onMouseOver={() => setShowInfoFor(section.id)}
                                                   onMouseLeave={() => setShowInfoFor(null)}
                                                // style={{ background: mapSelectedSection?.id === locationsData?.sections[2]?.id && '#b28d055c' }}

                                                   onClick={() =>
                                                     setMapSelectedSection(section)
                                                   }
                                              >
                                                  <Image src={locationsData?.logo} alt=""/>
                                              </div>
                                              <div
                                                className={`map-info ${showInfoFor === section.id ? '' : 'd-none'}`}>
                                                  <h3>{section.title.replace('Communities', '')}: </h3>
                                                  <span>{section.address}</span>
                                              </div>
                                          </div>
                                      :
                                        <>
                                        </>
                                  ))}
                                </>
                              }
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-4 mt-lg-0">
                            <div className="media-location-group">
                                {[...locationsData?.sections.filter(sect => !sect.is_coming_soon), ...locationsData?.sections.filter(sect => sect.is_coming_soon)].map((section) => (
                                    <div
                                        key={section.id}
                                        id={`${section.id}`}
                                        className="media-location"
                                        style={{display: 'flex'}}
                                        onClick={() => {
                                          setMapSelectedSection(section);
                                        }}
                                    >
                                        <div className="media-img">
                                            <Image
                                                src={section.image}
                                                alt=""
                                            />
                                        </div>
                                        <div className="media-body">
                                            <h3>{section.title}</h3>
                                            <p className='location-description'
                                               dangerouslySetInnerHTML={{
                                                   __html: section.content,
                                               }}
                                            ></p>
                                            <div>
                                                {
                                                    !section?.is_coming_soon &&
                                                    <Link
                                                        to={`/locations/${section?.id}/${section.name.replaceAll(' ', '-')}`}
                                                        className="link"
                                                    >
                                                        Read More
                                                    </Link>
                                                }
                                                {(section.address && !(section.address === 'Details coming soon!')) &&
                                                <>
                                                    {
                                                        !section?.is_coming_soon &&
                                                        <span className="link separator">|</span>
                                                    }
                                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${section.address}`}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                       className="link direction-link"
                                                    >
                                                        Directions
                                                    </a>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Location;

// <img sizes="100vw" src="http://localhost:8000/media/locations-map-pins.jpg">