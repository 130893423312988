const Page404 = () => {
  return (
    <section className="section-404">
      <div className="container">
        <div className="card-error">
          <h1>404</h1>
          <p>Page not found</p>
          <a href="/" className="btn btn-default">
            Come Back Home
          </a>
        </div>
      </div>
    </section>
  );
};

export default Page404;
