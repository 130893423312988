import { useSelector } from 'react-redux';
import TeamCarousel from '../components/teamsCarousel';
import AboutSection from '../components/aboutSection';
import Fade from '@material-ui/core/Fade';
import { Helmet } from 'react-helmet';
import Image from '../components/image';

const About = () => {

  const pageContent = useSelector(state => state.siteContents?.data?.about_page);

  return (
    <>
     <Helmet>
        <title>{pageContent?.page_title}</title>
        <meta name="description" content={pageContent?.page_description} />
      </Helmet>
      <div className="section section-about">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4 order-1">
              <Fade timeout={1000} in>
                <h2 className="section-title"
                dangerouslySetInnerHTML={{
                  __html: pageContent?.main_title
                }}
                >
                </h2>
              </Fade>
            </div>
            <div className="col-lg-7 col-xl-8 order-3 order-lg-2 mt-3 mt-lg-0">
              <Fade timeout={1000} in>
                <p className="section-text"
                  dangerouslySetInnerHTML={{ __html:pageContent?.main_content}}
                >
                </p>
              </Fade>
            </div>
            <div className="col-lg-5 col-xl-4 mt-lg-2 order-4 order-lg-3">
              <Fade timeout={1000} in>
                <div className="row">
                  {pageContent?.achievements?.map((achievement) => (
                    <div
                      key={achievement?.id}
                      className="col-6 col-lg-12 mt-xl-5"
                    >
                      <div className="about-item"
                      >
                        <h6>{achievement?.achievement_value}</h6>
                        <p
                        dangerouslySetInnerHTML={{
                          __html:achievement?.achievement_descriptions 
                        }}
                        >
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Fade>
            </div>
            <div className="col-lg-7 col-xl-8 mt-lg-2 order-2 order-lg-4">
              <div className="section-img-wrapper">
                <div className="section-img">
                  <Image src={pageContent?.main_image} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageContent?.sections?.map((section, index) => (
        <AboutSection
          key={index}
          data={section}
          index={index}
          side={index % 2 === 0 ? 'left' : 'right'}
        />
      ))}
      <TeamCarousel team_members={pageContent?.team_members} board_members={pageContent?.board_members} />
    </>
  );
};

export default About;
