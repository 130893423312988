import { useRef } from 'react';
import Slider from 'react-slick';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Image from './image';

const LocationCarousel = (props) => {
  const sliderRef = useRef(null);
  const RouteLocation = useLocation();

  const prevSlide = (e) => {
    e.preventDefault();
    sliderRef.current.slickPrev();
  };
  const nextSlide = (e) => {
    e.preventDefault();
    sliderRef.current.slickNext();
  };
  const sliderSettings = {
    customPaging: function customPaging(i){
      return <div id={i} className="dot"/>;
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="section  section-overflow  section-lightslider">
      <div className="container">
        <h2 className="section-title text-lg-center">Locations</h2>
        <p className="section-text text-lg-center w-lg-50 mx-auto"
        dangerouslySetInnerHTML={{
          __html: props?.main_content
        }}
        
        >
        </p>

        <div className="section-slide">
          <Slider
            className="section section-slide"
            style={{ padding: '0 0 50px 0' }}
            ref={sliderRef}
            {...sliderSettings}
          >
            {[...props?.locations.filter(location => !location.is_coming_soon), ...props?.locations.filter(location => location.is_coming_soon) ].map((location) => (
              <div
                key={location?.id}
                className="card card-location"
                style={{ height: '100%' }}
              >
                <div className="card-body">
                  <Image src={location?.image} className="card-img" />
                  <h3 className="card-title">{location?.title}</h3>
                  <p className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: location?.content
                      }}
                  />
                </div>
                <div className="card-footer">
                  <Link to={`/locations?current_location=${location?.id}`} className="link view-on-map">
                    View on Map
                  </Link>
                  {
                    location.is_coming_soon ?
                      location?.website ?
                        <a className="btn btn-default" href={location?.website} target="_blank"  rel="noreferrer">
                          Read More
                        </a>
                        :
                        <div className="disabled-button">
                          Coming soon
                        </div>
                        :
                    <Link
                        to={`/locations/${location?.id}/${location?.name?.replaceAll(' ', '-')}`}
                        className="btn btn-default"
                    >
                      Read More
                    </Link>
                  }
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="slider-controls">
          <Link
            to={RouteLocation.pathname}
            onClick={(e) => prevSlide(e)}
            className="slider-prev"
          >
            <ArrowLeftSLineIcon />
          </Link>
          <Link
            to={RouteLocation.pathname}
            onClick={(e) => nextSlide(e)}
            className="slider-next"
          >
            <ArrowRightSLineIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

LocationCarousel.propTypes = {
  main_content: PropTypes.string,
  locations: PropTypes.array,
};

export default LocationCarousel;
