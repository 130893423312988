import './App.css';
import NavBar from './components/navbar';
import Footer from './components/footer';
import About from './pages/about';
import './scss/style.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Contact from './pages/contacts';
import Location from './pages/locations';
import Lifestyle from './pages/lifestyle';
import Home from './pages/home';
import House from './pages/house';
import Page404 from './pages/page404';
import UserEntryPage from './pages/userEntry';
import {useSelector} from 'react-redux';
const ScrollToTop = () => {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};

const App = () => {
  const [navOnScroll, setNavOnScroll] = useState(false);
  const contents = useSelector(state => state?.siteContents);
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 60) {
        setNavOnScroll(true);
      } else {
        setNavOnScroll(false);
      }
    };
  }, []);
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <NavBar scroll={navOnScroll} />
        {
          contents.success &&
          <Switch>
            <Route path="/about">
              <About/>
            </Route>
            <Route path="/contacts">
              <Contact/>
            </Route>
            <Route path="/locations/:id/:title/:rel_id/waitlist">
              <UserEntryPage page_for="waitlist_page"/>
            </Route>
            <Route path="/locations/:id/:title/:rel_id/tour">
              <UserEntryPage page_for="schedule_tour_page"/>
            </Route>
            <Route path="/locations/:id/:title">
              <House/>
            </Route>

            <Route path="/locations">
              <Location/>
            </Route>
            <Route path="/lifestyle">
              <Lifestyle/>
            </Route>

            <Route path="/house">
              <House/>
            </Route>
            <Route path="/404">
              <Page404/>
            </Route>

            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        }
        <Footer />
      </Router>
    </div>
  );
};

export default App;
