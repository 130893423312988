import {useSelector} from 'react-redux';
import {Fade} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import Image from '../components/image';

const Lifestyle = () => {
    const lifestyleData = useSelector(
        (state) => state.siteContents?.data?.lifestyle_page
    );
    return (
        <>
            <Helmet>
                <title>{lifestyleData?.page_title}</title>
                <meta name="description" content={lifestyleData?.page_description}/>
            </Helmet>
            <div className="page-lifestyle">
                <div className="section section-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-xl-4 order-1">
                                <Fade timeout={1000} in>
                                    <h2 className="section-title mb-3 mb-lg-5">
                                        {lifestyleData?.main_title}
                                    </h2>
                                </Fade>
                            </div>
                            <div className="col-lg-7 col-xl-8 order-3 order-lg-2 mt-3 mt-lg-0">
                                <Fade timeout={1000} in>
                                    <p className="section-text"
                                       dangerouslySetInnerHTML={{__html: lifestyleData?.main_content}}
                                    >
                                    </p>
                                </Fade>
                            </div>

                            <div className="section-img-wrapper order-2">
                                <div className="section-img">
                                    <Image src={lifestyleData?.main_image} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="section-title mb-0">
                                    {lifestyleData?.tech_title}
                                </h2>
                            </div>
                            <div className="col-lg-6 order-2 order-lg-1 mt-4 mt-lg-0">
                                <p
                                    className="section-text"
                                    dangerouslySetInnerHTML={{
                                        __html: lifestyleData?.tech_content,
                                    }}
                                ></p>
                            </div>
                            <div className="col-lg-4 order-4 order-lg-2">
                                <div className="section-img-wrapper two mt-lg-5">
                                    <div className="section-img">
                                        <Image src={lifestyleData?.tech_image1} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-1 order-lg-3 mt-4 mt-lg-0">
                                <div className="section-img-wrapper four">
                                    <div className="section-img">
                                        <Image src={lifestyleData?.tech_image2} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 d-none d-lg-block order-lg-4">
                                <div className="section-img-wrapper three mt-5">
                                    <div className="section-img">
                                        <Image src={lifestyleData?.tech_image3} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 d-none d-lg-block order-lg-5"
                                style={{
                                    marginTop: '15px',
                                }}
                            >
                                <div className="section-img-wrapper">
                                    <div className="section-img">
                                        <Image src={lifestyleData?.tech_image4} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section section-carefree">
                    <div className="container">
                        <h2 className="section-title px-lg-5 mx-lg-auto mb-lg-5 text-lg-center">
                            {lifestyleData?.living_title}
                        </h2>
                        <div className="row flex-column">
                            <div className="col-lg-4 order-2 order-lg-1 mt-3 mt-lg-0">
                                <p
                                    className="section-text mb-0"
                                    dangerouslySetInnerHTML={{
                                        __html: lifestyleData?.living_content1,
                                    }}
                                ></p>
                            </div>
                            <div className="col-lg-4 order-4 order-lg-2 mt-3 mt-lg-4 mb-3">
                                <p
                                    className="section-text mb-2"
                                    dangerouslySetInnerHTML={{
                                        __html: lifestyleData?.living_content2,
                                    }}
                                ></p>
                            </div>
                            <div className="col-lg-4 order-3 order-lg-3 mt-4 mt-lg-auto">
                                <div className="section-img-wrapper">
                                    <div className="section-img">
                                        <Image src={lifestyleData?.living_image1} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-1 order-lg-4">
                                <div className="section-img-wrapper">
                                    <div className="section-img">
                                        <Image src={lifestyleData?.living_image2} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Lifestyle;
