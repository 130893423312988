import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getSiteContent} from '../redux/site_contents/site.actions';
import Image from './image';


const NavBar = (props) => {
    const dispatch = useDispatch();
    const currLocation = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    let logo_list = useSelector(state => state.siteContents?.data?.logos);
    let home_page = useSelector(state => state.siteContents?.data?.home_page);
    const isLoaded = useSelector(state => state.siteContents?.success);
    const light_nav = home_page?.text_has_background ? 'light-nav' : '';
    const dark = currLocation.pathname !== '/' ? 'dark' : light_nav;

    const map_logos = () => {
        let values = {};
        logo_list?.forEach(element => {
            values = {
                ...values,
                [element.name]: element.image,
            };
        });
        return values;
    };

    const logos = map_logos();

    useEffect(() => {
        dispatch(getSiteContent());
    }, [dispatch]);
    const home = '/',
        about = '/about',
        location = '/locations',
        lifestyle = '/lifestyle',
        contact = '/contacts';

    return (
        <>
            {
                isLoaded &&
            <header
                className={`header ${dark} ${props.scroll && 'scroll'}  ${
                    showMenu && 'show-nav'
                }`}
            >
                <div className="container">
                    <nav className="nav nav-header">
                        <Link
                            onClick={() => setShowMenu(false)}
                            to={home}
                            className="nav-link"
                        >
                            Home
                        </Link>
                        <Link
                            to={lifestyle}
                            onClick={() => setShowMenu(false)}
                            className={`nav-link ${
                                currLocation.pathname === lifestyle && 'active'
                            }`}
                        >
                            Lifestyle
                        </Link>
                        <Link
                            to={location}
                            onClick={() => setShowMenu(false)}
                            className={`nav-link ${
                                (currLocation.pathname === location || currLocation.pathname.startsWith(location)) && 'active'
                            }`}
                        >
                            Locations
                        </Link>
                    </nav>
                    <Link to="/" onClick={() => setShowMenu(false)} className="header-logo">
                        <Image
                            src={logos && logos['logo-dark-mobile']}
                            className="dark-mobile"
                            alt=""
                        />
                        <Image src={logos && logos['logo-dark']} className="dark"/>
                        <Image
                            src={logos && logos['logo-mobile']}
                            className="light-mobile"
                            alt=""
                        />
                        <Image src={logos && logos['logo']} className="light" alt=""/>
                    </Link>
                    <nav className="nav nav-header">

                        <Link
                            to={about}
                            onClick={() => setShowMenu(false)}
                            className={`nav-link ${
                                currLocation.pathname === about && 'active'
                            }`}
                        >
                            About Us
                        </Link>

                        <Link
                            to={contact}
                            onClick={() => setShowMenu(false)}
                            className={`nav-link ${
                                currLocation.pathname === contact && 'active'
                            }`}
                        >
                            Contact Us
                        </Link>
                    </nav>
                    <Link
                        to="#"
                        id="headerMenu"
                        onClick={() => setShowMenu(!showMenu)}
                        className="header-menu"
                    />
                </div>
            </header>
            }
        </>
    );
};

NavBar.propTypes = {
    scroll: PropTypes.any,

};


export default NavBar;
