import { USERCONTACT, SCHEDULETOUR, WAITLIST } from './misc.types';

const INITIAL_STATE = {
  loading: false,
};

export const UserContactReducer = (state = INITIAL_STATE, action) => {
  const { type } = action;
  let data = action?.payload?.response?.data;

  switch (type) {
    case `${USERCONTACT}_PENDING`:
      return {
        ...state,
        loading: true,
      };
    case `${USERCONTACT}_FULFILLED`:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
      case `${USERCONTACT}_REJECTED`:
      return {
        ...state,
        loading: false,
        success: false,
        error: data,
      };
    default:
      return state;
  }
};

export const  ScheduleTourReducer = (state = INITIAL_STATE, action) => {
  const { type } = action;
  let data = action?.payload?.response?.data;

  switch (type) {
    case `${SCHEDULETOUR}_PENDING`:
      return {
        ...state,
        loading: true,
      };
    case `${SCHEDULETOUR}_FULFILLED`:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
      case `${SCHEDULETOUR}_REJECTED`:
      return {
        ...state,
        loading: false,
        success: false,
        error: data,
      };
    default:
      return state;
  }
};


export const  JoinWaitlistReducer = (state = INITIAL_STATE, action) => {
  const { type } = action;
  let data = action?.payload?.response?.data;

  switch (type) {
    case `${WAITLIST}_PENDING`:
      return {
        ...state,
        loading: true,
      };
    case `${WAITLIST}_FULFILLED`:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
      case `${WAITLIST}_REJECTED`:
      return {
        ...state,
        loading: false,
        success: false,
        error: data,
      };
    default:
      return state;
  }
};
