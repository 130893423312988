import PropTypes from 'prop-types';
import Image from './image';

const AboutSection = (props) => {
  return (
    <div
      key={props.index}
      className={`section ${
        props.side === 'left' ? 'pt-3 pt-lg-5' : ''
      } pb-3 pb-lg-5`}
    >
      <div className="container">
        {props.side === 'left' ? (
          <div className="row">
            {props.index === 0 ? (
              <div className="col-lg-12">
                <h2 className="section-title">Our values</h2>
              </div>
            ) : (
              ''
            )}
            <div className="col-lg-8 mt-2">
              <div className="section-img-wrapper">
                <div className="section-img">
                  <Image src={props.data?.image} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-2">
              <h3 className="section-subtitle mb-3 mb-lg-4">
                {props.data?.title}
              </h3>
              <p
    className="section-text"
    dangerouslySetInnerHTML={{__html: props.data?.content}}
    />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-4 order-2 order-lg-1 mt-4 mt-lg-0">
              <h3 className="section-subtitle mb-4">{props.data?.title}</h3>
              <p
    className="section-text"
    dangerouslySetInnerHTML={{__html: props.data?.content}}
    />
            </div>
            <div className="col-lg-8 order-1 order-lg-2">
              <div className="section-img-wrapper">
                <div className="section-img">
                  <Image src={props.data?.image} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AboutSection.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  side: PropTypes.any,
};

export default AboutSection;
