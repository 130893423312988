import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import Image from './image';

const HouseGalleryCarousel = (props) => {
  const sliderRef = useRef(null);
  const mobile = useMediaQuery('(max-width:991px)');
  const [selectedImage, setSelectedImage] = useState(null);

  const prevSlide = (e) => {
    e.preventDefault();
    sliderRef.current.slickPrev();
  };
  const nextSlide = (e) => {
    e.preventDefault();
    sliderRef.current.slickNext();
  };
  const onGalleryItemClick = (e, image) => {
    e.preventDefault();
    if (!dragging) {
      setSelectedImage(image);
      setShowModal(true);
    }
  };
  const [dragging, setDragging] = useState(false);

  const mouseMoveDrag = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const CompleteDrag = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const mouseDownCarousel = (e) => {
    e.preventDefault();
    document.onmousemove = mouseMoveDrag;
    document.onmouseup = CompleteDrag;
    setDragging(false);
  };

  const [showModal, setShowModal] = useState(false);

  const sliderSettings = {
    customPaging: function customDots(i) {
      return <div id={i} className="dot" />;
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="section secondary section-overflow section-lightslider">
        <div className="container">
          <h2 className="section-title">Gallery</h2>
          <Slider
            className=" section section-slide"
            style={{
              padding: '0 0 50px 0',
            }}
            ref={sliderRef}
            {...sliderSettings}
          >
            {/* <div className="section-slide"> */}
            {props?.images?.map((image, index) => (
              <div
                key={index}
                className="section-img-wrapper three"
                onClick={(e) => onGalleryItemClick(e, image?.image)}
                onDrag={() => null}
                onMouseDown={(e) => mouseDownCarousel(e)}
              >
                <div className="section-img">
                  <Image src={image?.image} />
                </div>
              </div>
            ))}
          </Slider>
          <div className="slider-controls">
            <Link to="#" onClick={(e) => prevSlide(e)} className="slider-prev">
              <ArrowLeftSLineIcon />
            </Link>
            <Link to="#" onClick={(e) => nextSlide(e)} className="slider-next">
              <ArrowRightSLineIcon />
            </Link>
          </div>

        </div>
      </div>
      <Modal
        open={showModal}
        style={{
          display: 'flex',
          alignItems: mobile ? 'flex-start' : 'center',
          justifyContent: 'center',
        }}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100vw',
            outline: '0px',
            backgroundImage: mobile && `url(${selectedImage})`,
          }}
          className="location-image-view"
        >
          <span
            style={{
              position: 'absolute',
              color: 'white',
              right: '5px',
              top: '5px',
            }}
            onClick={() => setShowModal(false)}
          >
            <CloseCircleLineIcon />
          </span>
          {!mobile && (
            <Image
              style={{
                height: mobile ? 'auto' : '98vh',
                width: mobile ? '100vw' : 'auto',
                maxWidth: '100vw',
              }}
              src={selectedImage}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

HouseGalleryCarousel.propTypes = {
  images: PropTypes.array,
};

export default HouseGalleryCarousel;
