import { GETSITECONTENT } from './site.types';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GETSITECONTENT}_PENDING`:
      return {
        ...state,
        loading: true,
      };
    case `${GETSITECONTENT}_FULFILLED`:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload.data,
        error: null,
      };
    case `${GETSITECONTENT}_REJECTED`:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
