// import FacebookFillIcon from 'remixicon-react/FacebookFillIcon';
// import InstagramFillIcon from 'remixicon-react/InstagramFillIcon';
// import YoutubeFillIcon from 'remixicon-react/YoutubeFillIcon';
// import TwitterFillIcon from 'remixicon-react/TwitterFillIcon';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import Image from './image';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const contactData = useSelector(
        (state) => state.siteContents?.data?.contact_page
    );
    const isLoaded = useSelector(state => state.siteContents?.success);

    let logo_list = useSelector((state) => state.siteContents?.data?.logos);
    const map_logos = () => {
        let values = {};
        logo_list?.forEach((element) => {
            values = {
                ...values,
                [element.name]: element.image,
            };
        });
        return values;
    };

    const logos = map_logos();
    return (
        <>
            {isLoaded &&
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 order-1">
                                <Link to="/" className="footer-logo">
                                    <Image src={logos && logos['logo-dark-mobile']} />
                                </Link>
                            </div>
                            <div className="col-lg-3 order-3 order-lg-2">
                                {contactData?.sections?.map((section) => (
                                    <div key={section?.id} className="address-item">
                                        <h6>{section?.office}</h6>
                                        <p>
                                            <a
                                                href={`${section?.link_to_map}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'white'
                                                }}
                                            >
                                                {section?.address} <br />
                                                {
                                                    !!section?.city && <span> {section?.city},  </span>
                                                }
                                                {
                                                    !!section?.state && <span> {section?.state} </span>
                                                }
                                                {
                                                    !!section?.zipcode && <span> {section?.zipcode} </span>
                                                }
                                                <br />
                                                {section?.phone}
                                            </a>
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <div className="col-lg-3 order-2 order-lg-3">

                            </div>
                            <div className="col-lg-3 order-4">
                                <nav className="nav nav-footer">
                                    <Link to="/lifestyle" className="nav-link">
                                        Lifestyle
                                    </Link>
                                    <Link to="/locations" className="nav-link">
                                        Locations
                                    </Link>
                                    <Link to="/about" className="nav-link">
                                        About Us
                                    </Link>
                                    <Link to="/contacts" className="nav-link">
                                        Contact Us
                                    </Link>
                                </nav>
                            </div>
                        </div>

                        <div className="d-lg-flex justify-content-between mt-4 mt-lg-5">
                            <div className="footer-item">
                                <Link to="#">Privacy Policy</Link>
                                <Link to="#">Terms of Use</Link>
                            </div>
                            <div className="footer-item">
                                <span>{ currentYear }</span>
                                <span>TerraLane Communities</span>
                            </div>
                        </div>
                    </div>
                </footer>
            }
        </>
    );
};

export default Footer;
