import { useSelector } from 'react-redux';
import UserEntryForm from '../components/userEntryForm';
import PropTypes from 'prop-types';
import Image from '../components/image';
//import Imgix from 'react-imgix';


const UserEntryPage = (props) => {
  const siteData = useSelector((state) => state.siteContents?.data) || {};
  const data = siteData[props?.page_for] || {};
  console.log(data?.image);
  return (
    <div className="section section-header" >
      <div className="container">
        <div className="row">
          <div className="col d-none d-lg-block">
            <div className="section-img-wrapper mg-r-35">
              <div className="section-img">
                {/*<Imgix src={data?.image}  />*/}
                <Image src={data?.image} alt="" />
              </div>
            </div>
          </div>
          <UserEntryForm
            {...data}
            page = {props?.page_for}
            action_text="Join Waitlist"
          />
        </div>
      </div>
    </div>
  );
};

UserEntryPage.propTypes = {
  page_for: PropTypes.string,
};

export default UserEntryPage;
